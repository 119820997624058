import { createMuiTheme } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#58c0d0",
      main: "#128f9f",
      dark: "#006171",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffa552",
      main: "#f47421",
      dark: "#bb4500",
      contrastText: "#fff",
    },
    default: grey,
    background: {
      // default: '#ffffff',
      paper: '#ffffff'
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflowX: 'hidden',
        },
        body: {
          background: `linear-gradient(180deg, #128f9f 50%, #006171 )`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif'
  }
});

export default theme;