exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-pages-contact-js": () => import("./../../../src/pages/pages/contact.js" /* webpackChunkName: "component---src-pages-pages-contact-js" */),
  "component---src-pages-pages-opout-js": () => import("./../../../src/pages/pages/opout.js" /* webpackChunkName: "component---src-pages-pages-opout-js" */),
  "component---src-pages-pages-privacy-js": () => import("./../../../src/pages/pages/privacy.js" /* webpackChunkName: "component---src-pages-pages-privacy-js" */),
  "component---src-pages-pages-terms-js": () => import("./../../../src/pages/pages/terms.js" /* webpackChunkName: "component---src-pages-pages-terms-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index.template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-widget-template-js": () => import("./../../../src/templates/widget.template.js" /* webpackChunkName: "component---src-templates-widget-template-js" */)
}

